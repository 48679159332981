<template>
  <article v-loading="loading">
    <RouterNav name="试题预览"></RouterNav>
    <div class="title">
      <div class="left">
        <div class="line"></div>
        <div class="nowrap">试卷名称：</div>
        <el-input
            class="name-input"
            placeholder="请输入名称"
            :clearable="true"
            v-model="name"
            v-show="!isJoin"
        >
        </el-input>
        <b v-show="isJoin">{{name}}</b>
      </div>
      <el-button :class="['btn', allSelected ? '' : 'btn-2']" @click="allTrigger">{{allSelected ? '全不选' : '全选'}}
      </el-button>
    </div>
    <div class="wrapper">
      <div class="type-tips"><span v-for="item in writtenArr" :key="item.writeLibType"><span v-if="item.arr.length">{{item.arr[0].writeLibTypeName}}{{item.arr.length}}题、</span></span>预计耗时{{costTime}}分钟
      </div>
      <div class="title-list" v-for="(item, index) in writtenArr" :key="item.writeLibType">
        <div class="writeLib" v-if="item.arr.length">
          <div class="writeLibTypeName" v-show="!isJoin">
            <div>以下是{{item.arr[0].writeLibTypeName}}</div>
            <div class="move-btn-group">
              <span class="mr-56">整组移动：</span>
              <span :class="['btn', 'mr-28', index === 0 ? 'disabled' : '']" @click="writtenIndexUp(index)">上移</span>
              <span :class="['btn', index === writtenArr.length - 1 ? 'disabled' : '']"
                    @click="writtenIndexDown(index)">下移</span>
            </div>
          </div>
        </div>
        <div
            class="written-list"
            v-for="(written, wIndex) in item.arr"
            :key="written.id"
            v-show="!isJoin || (isJoin && written.selected)"
        >
          <component
              :is="written.type === '1' ? 'MultipleChoice'
          : written.type === '2' ? 'ClozeTest'
          : written.type === '3' ? 'ReadingComprehension'
          : written.type === '4' ? 'LexicalManipulation'
          : written.type === '6' ? 'BlankFilling'
          : written.type === '7' ? 'ReadingSort'
          : written.type === '8' ? 'MultipleFilling' : ''"
              :item="written"
              :showTranslate="written.showTranslate"
              class="written"
          >
          </component>
          <div class="shop">
            <div class="left">
              <div class="tag">{{written.writeLibTypeName}}</div>
              <div class="tag" v-if="written.difficultyName">
                难度：<span class="col-FF3C30">{{written.difficultyName}}</span>
              </div>
              <div class="tag">
                我已布置：<span class="col-329cf3">{{written.frequency}}次</span>
              </div>
            </div>
            <div class="right">
              <div class="move-btn-group" v-show="!isJoin">
                <span :class="['btn', 'mr-28', wIndex === 0 ? 'disabled' : '']"
                      @click="arrIndexUp(index, wIndex)">上移</span>
                <span :class="['btn', wIndex === item.arr.length - 1 ? 'disabled' : '']"
                      @click="arrIndexDown(index, wIndex)">下移</span>
              </div>
              <div class="line" v-show="!isJoin">|</div>
              <div class="show-btn" @click="translateTrigger(index, wIndex)">
                <div>{{written.showTranslate ? '收起解析' : '展开解析'}}</div>
                <div :class="['icon', written.showTranslate ? 'rotate-180' : '']"></div>
              </div>
              <xybutton
                  :type="written.selected ? 'del' : 'add'"
                  @click="arrSelect(index, wIndex)"
                  v-show="!isJoin"
              >{{written.selected ? '移除' : '添加'}}
              </xybutton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="flex row">
        <div>
          <span>已选：</span><span class="col-329cf3">{{selectedNum}}题</span>
          <span class="ml-28">预计耗时：</span><span class="col-329cf3">{{costTime}}分钟</span>
        </div>
        <div class="flex" v-show="!isJoin">
          <xybutton
              class="button"
              @click="writtenReview"
          >继续添加
          </xybutton>
          <xybutton
              class="button button-2 ml-28"
              :disabled="selectedNum === 0"
              @click="isJoinChange"
          >加入清单
          </xybutton>
        </div>
        <div class="flex" v-show="isJoin">
          <xybutton
              class="button button-3"
              @click="isJoinChange"
          >移出清单
          </xybutton>
          <xybutton
              class="button ml-28"
              @click="assignWork"
          >继续布置其它内容
          </xybutton>
        </div>
      </div>
    </div>

    <Trolley
        @listState="listState"
    ></Trolley>
  </article>
</template>

<script>
  import RouterNav from '@/components/RouterNav.vue'
  import MultipleChoice from '../components/written/MultipleChoice'
  import ClozeTest from '../components/written/ClozeTest'
  import ReadingComprehension from '../components/written/ReadingComprehension'
  import LexicalManipulation from '../components/written/LexicalManipulation'
  import BlankFilling from '../components/written/BlankFilling'
  import ReadingSort from '../components/written/ReadingSort'
  import MultipleFilling from '../components/written/MultipleFilling'
  import Trolley from './components/trolley.vue'

  export default {
    name: 'writtenReview',
    components: {
      RouterNav,
      MultipleChoice,
      ClozeTest,
      ReadingComprehension,
      LexicalManipulation,
      BlankFilling,
      ReadingSort,
      MultipleFilling,
      Trolley
    },
    data() {
      return {
        loading: false,
        name: '',
        writtenArr: [],
        written: {},
        writtenNum: 0,
        writtenTime: 0,
        selectedNum: 0,
        costTime: 0,
        isJoin: false
      }
    },
    computed: {
      allSelected() {
        return this.selectedNum && this.selectedNum === this.writtenNum
      }
    },
    mounted() {
      this.indexModuleName = this.$route.query.indexModuleName
      this.moduleName = this.$route.query.moduleName
      this.moduleId = this.$route.query.moduleId
      const date = new Date()
      this.name = `笔试专区${this.common.formatDate('yyyyMMdd', date)}`
      const {preliminWritten} = this.common.globalData
      if (!preliminWritten || !preliminWritten.writtenObj) return
      this.costTime = this.writtenTime = preliminWritten.time
      const {writtenObj} = preliminWritten
      const writtenArr = []
      Object.keys(writtenObj).forEach(item => {
        const {seq} = writtenObj[item]
        delete writtenObj[item].seq
        const arr = Object.values(writtenObj[item])
        arr.sort(this.common.compare('seq'))
        const obj = {
          writeLibType: item,
          seq,
          arr
        }
        writtenArr.push(obj)
      })
      writtenArr.sort(this.common.compare('seq'))
      let selectedNum = 0
      for (let i = 0, len = writtenArr.length; i < len; i += 1) {
        const {arr} = writtenArr[i]
        selectedNum += arr.length
        for (let j = 0, len = arr.length; j < len; j += 1) {
          const {libList} = arr[j]
          for (let k = 0, len = libList.length; k < len; k += 1) {
            const {type} = libList[k]
            const pattern = /(\d+\.){1}/
            switch (type) {
              case '1':
              case '6':
                libList[k].titleType.text = libList[k].titleType.text.replace(pattern, '')
                break
            }
          }
        }
      }
      this.writtenArr = writtenArr
      this.selectedNum = this.writtenNum = selectedNum
    },
    beforeDestroy() {
      if (!this.isJoin) {
        const {writtenArr} = this
        for (let i = 0, len = writtenArr.length; i < len; i += 1) {
          const {arr} = writtenArr[i]
          for (let j = arr.length - 1; j >= 0; j -= 1) {
            if (!arr[j].selected) arr.splice(j, 1)
          }
        }
        const writtenObj = {}
        for (let i = 0, len = writtenArr.length; i < len; i += 1) {
          const {arr} = writtenArr[i]
          if (arr.length > 0) {
            const {writeLibType} = writtenArr[i]
            writtenObj[writeLibType] = {}
            writtenObj[writeLibType].seq = i
            for (let j = 0, len = arr.length; j < len; j += 1) {
              arr[j].seq = j
              writtenObj[writeLibType][arr[j].id] = arr[j]
            }
          }
        }
        this.common.globalData.preliminWritten = {
          writtenObj,
          time: this.costTime
        }
      }
    },
    methods: {
      listState() {
        if (this.isJoin && !this.$store.state.selectedWorkList[this.id]) {
          this.isJoinChange()
        }
      },
      writtenIndexUp(index) {
        if (index === 0) return
        const {writtenArr} = this
        this.writtenArr = this.common.swapArray(writtenArr, index, index - 1)
      },
      writtenIndexDown(index) {
        const {writtenArr} = this
        if (index === writtenArr.length - 1) return
        this.writtenArr = this.common.swapArray(writtenArr, index, index + 1)
      },
      arrIndexUp(index, wIndex) {
        if (wIndex === 0) return
        const {writtenArr} = this
        let {arr} = writtenArr[index]
        arr = this.common.swapArray(arr, wIndex, wIndex - 1)
        writtenArr[index].arr = arr
        this.$set(this.writtenArr, index, writtenArr[index])
      },
      arrIndexDown(index, wIndex) {
        const {writtenArr} = this
        let {arr} = writtenArr[index]
        if (wIndex === arr.length - 1) return
        arr = this.common.swapArray(arr, wIndex, wIndex + 1)
        writtenArr[index].arr = arr
        this.$set(this.writtenArr, index, writtenArr[index])
      },
      allTrigger() {
        const {allSelected} = this
        const {writtenArr} = this
        if (allSelected) {
          this.selectedNum = 0
          this.costTime = 0
        } else {
          this.selectedNum = this.writtenNum
          this.costTime = this.writtenTime
        }
        for (let i = 0, len = writtenArr.length; i < len; i += 1) {
          const {arr} = writtenArr[i]
          for (let j = 0, len = arr.length; j < len; j += 1) {
            arr[j].selected = !allSelected
          }
        }
      },
      arrSelect(index, wIndex) {
        const {writtenArr} = this
        const {arr} = writtenArr[index]
        arr[wIndex].selected = !arr[wIndex].selected
        this.$set(writtenArr[index], 'arr', arr)
        if (arr[wIndex].selected) {
          this.costTime += arr[wIndex].time
          this.selectedNum += 1
        } else {
          this.costTime -= arr[wIndex].time
          this.selectedNum -= 1
        }
      },
      translateTrigger(index, wIndex) {
        const {writtenArr} = this
        const {arr} = writtenArr[index]
        arr[wIndex].showTranslate = !arr[wIndex].showTranslate
        this.$set(this.writtenArr, index, writtenArr[index])
      },
      isJoinChange() {
        if (!this.name) {
          this.$alert('请输入名称', '提示', {
            confirmButtonText: '确定'
          })
          return
        }
        this.isJoin = !this.isJoin
        if (!this.isJoin) {
          const {writtenArr} = this
          for (let i = 0, len = writtenArr.length; i < len; i += 1) {
            const {arr} = writtenArr[i]
            for (let j = 0, len = arr.length; j < len; j += 1) {
              arr[j].selected = false
            }
          }
          this.selectedNum = 0
          this.costTime = 0
          this.$store.commit('listDel', {id: this.id})
        } else {
          const {writtenArr} = this
          const newWrittenArr = []
          for (let i = 0, len = writtenArr.length; i < len; i += 1) {
            const {arr} = writtenArr[i]
            const obj = JSON.parse(JSON.stringify(writtenArr[i]))
            obj.arr = []
            for (let j = 0, len = arr.length; j < len; j += 1) {
              if (arr[j].selected) {
                obj.arr.push(arr[j])
              }
            }
            if (obj.arr.length > 0) newWrittenArr.push(obj)
          }
          const writtenObj = {}
          for (let i = 0, len = newWrittenArr.length; i < len; i += 1) {
            const {arr} = newWrittenArr[i]
            if (arr.length > 0) {
              const {writeLibType} = newWrittenArr[i]
              writtenObj[writeLibType] = {}
              writtenObj[writeLibType].seq = i
              for (let j = 0, len = arr.length; j < len; j += 1) {
                arr[j].seq = j
                writtenObj[writeLibType][arr[j].id] = arr[j]
              }
            }
          }
          const UUID = this.common.getUUID(32)
          this.id = UUID
          const obj = {
            id: UUID,
            type: 'written',
            name: this.name,
            indexModuleName: this.indexModuleName,
            moduleName: this.moduleName,
            moduleId: this.moduleId,
            writtenObj,
            time: this.costTime
          }
          this.$store.commit('listAdd', obj)
          this.common.globalData.preliminWritten = {}
        }
      },
      writtenReview() {
        this.$router.back()
      },
      assignWork() {
        this.$router.push({
          name: 'assignWork'
        })
      }
    }
  }
</script>

<style>
  .name-input .el-input__inner {
    background: #F6F6F6 url("../../../assets/input.png") no-repeat 20px center;
    background-size: 20px 20px;
    width: 300px;
    height: 44px;
    border-color: transparent;
    border-radius: 22px;
    padding-left: 50px;
    margin-left: 10px;
  }
</style>
<style scoped lang="scss">
  .title {
    @include flex(space-between);
    background-color: #fff;
    height: 66px;
    padding-right: 28px;
    margin-bottom: 10px;

    .left {
      @include flex;
      @include font(17px, #333);

      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }

    .btn {
      background-color: #F6F6F6;
      width: 90px;
      height: 34px;
      border-radius: 20px;
      border-color: transparent;
      @include font(15px, #309AF2, center);
    }
  }

  .wrapper {
    background-color: #fff;
    height: calc(100vh - 284px);
    overflow: auto;
    padding: 0 28px 20px;
    font-size: 17px;
    color: #333;
    position: relative;
    z-index: 90;

    .type-tips {
      padding: 12px 0;
      color: #309AF2;
      font-size: 14px;
    }

    .writeLib {
      margin-bottom: 10px;

      .writeLibTypeName {
        display: flex;
        justify-content: space-between;
        background-color: #F6F6F6;
        height: 54px;
        line-height: 54px;
        padding: 0 28px 0 14px;
        font-weight: bold;
      }
    }

    .written-list {
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    .written {
      padding: 20px 20px 0;
    }

    .shop {
      @include flex(space-between);
      background-color: #fff;
      height: 54px;
      padding: 0 28px;
      border-top: 1px solid #F1F1F1;
      margin-bottom: 5px;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .tag {
        background-color: #F6F6F6;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 12px;
        text-align: center;
      }

      .show-btn {
        @include flex;
        margin-right: 28px;
        color: #309AF2;
        font-size: 14px;
        cursor: pointer;

        .icon {
          background-image: url("../../../assets/z-1.png");
          background-size: 100% 100%;
          width: 7px;
          height: 4px;
          margin-left: 4px;
        }
      }

      .line {
        margin: 0 28px;
        color: #E5E5E5;
      }

      .select-btn {
        width: 67px;
        height: 30px;
        border-radius: 15px;
        font-size: 13px;
        color: #FF3C30;
        border-color: #FF3C30;
      }
    }

    .move-btn-group {
      color: #309AF2;
      font-size: 17px;
      font-weight: normal;

      .btn {
        display: inline-block;
        cursor: pointer;
      }

      .disabled {
        color: #C0E0FB;
      }

      .mr-28 {
        margin-right: 28px;
      }

      .mr-56 {
        margin-right: 56px;
      }
    }
  }

  .footer {
    background-color: #fff;
    height: 84px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    position: relative;
    z-index: 99;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
    }

    .row {
      padding: 0 28px;
    }

    .col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      background-color: #EAF4FD;
      width: 250px;
      height: 40px;
      border-color: #309AF2;
      font-size: 17px;
      color: #309AF2;
    }

    .button-2 {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      border-color: transparent;
      color: #fff;
    }

    .button-3 {
      background-color: #FF3C30;
      color: #fff;
      border-color: transparent;
    }
  }
</style>
